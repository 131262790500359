.text-center {
  margin-left: auto;
  margin-right: auto;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

.resume-pic {
  -webkit-border-radius: 125px;
  -moz-border-radius: 125px;
  border-radius: 125px;
}

.certificate img {
  width: 70px !important;
  /*float:left;*/
}

.text-indent {
  text-indent: 50px;
}

.modal-dialog {
  max-width: 800px !important;
}

.navbar .navbar-nav {
  float: none;
  vertical-align: top;
  margin-top: 5px !important;
}

.navbar .navbar-collapse {
  text-align: center;
}

.navbar-nav li a {
  color: #fff !important;
  font-size: 20px;
  font-family: Georgia, Palatino, Palatino Linotype, Times, Times New Roman, serif;
  margin-top: 15px;
}

.navbar-inverse {
  background: rgba(0, 0, 0, 0.8);
}

.navbar-brand {
  font-size: 30px;
}

.text-center {
  text-align: center;
}

.images {
  text-align: center;
}

.images img {
  cursor: pointer;
  width: 5%;
  margin-right: 5px;
}

.btn-social-icon {
  margin-right: 5px;
}

.modal-body img {
  width: 100%;
}

.my-images {
  margin-top: 50px;
}

.my-social-media {
  margin-top: 100px;
}

/*   RESUME PAGE    */

.mht-resume {
  height: 800px;
  font-size: 12px;
  padding: 0;
}

/*   RESUME LEFT    */
.resume-left {
  text-align: right;
  background-color: #f1f1f1;
  padding-top: 25px;
}

.resume-left img {
  width: 100%;
  cursor: pointer;
}

.resume-pic {
  margin-top: 10px;
  background: white;
  padding: 5px;
  border: 3px #e0dada solid;
}

.resume-profile {
  margin-top: 15px;
  font-family: Lato, arial, sans-serif;
}

.resume-certificate {
  margin-top: 15px;
  font-family: Lato, arial, sans-serif;
}

.resume-certificate small {
  float: left;
  margin-top: 20px;
}

.resume-certificate div {
  margin-bottom: 10px;
}

.resume-print {
  float: right;
  cursor: pointer;
  margin-right: 10px;
}

.resume-download {
  display: none;
}

.resume-download {
  float: right;
  cursor: pointer;
  margin-right: 20px;
}

.resume-slash {
  font-size: 18px;
  font-weight: bold;
  color: #c7c5c5;
}

.resume-header {
  font-size: 18px;
  color: #de8629;
  font-weight: bold;
}

.line {
  height: 1px;
  background-color: #dedede;
  margin: 10px 0px 10px 0px;
}

.resume-social {
  margin-top: 25px;
  line-height: 35px;
}

.resume-social img {
  width: 13%;
}

.resume-social a {
  background-color: #444;
}

.certificate {
  cursor: pointer;
}

.certificate:hover {
  color: orange;
  font-weight: bold;
}

/*   RESUME RIGHT    */

.resume-right {
  padding-top: 25px;
}

.enfortek {
  margin-top: 20px;
}

.about-me {
  font-family: Lato, arial, sans-serif;
  font-size: 14px;
  padding-bottom: 21px;
}

.experience-date {
  font-size: 12px;
  color: #de8629;
  font-weight: bold;
  text-align: center;
  margin-top: 4px;
}

.resume-title {
  float: right;
  margin-top: 4px;
  margin-right: 10px;
}

.job-company {
  font-size: 15px;
  font-weight: bold;
}

.job-detail {
  padding: 10px 0 10px 0;
}

.text-right {
  text-align: right;
}

.resume-educations .row {
  margin-top: 15px;
}

/* SKILS */

.resume-skills span {
  font-size: 17px;
  color: orange;
  float: right;
  margin-top: -4px;
}

.resume-skills .row {
  padding-right: 15px;
  padding-left: 15px;
}

.resume-skills strong {
  margin: 10px 0 10px 0;
}

.skill-header {
  font-size: 15px;
  font-weight: bold;
  margin: 10px 0 10px 0;
}

.education-videos h2 {
  width: 330px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
}

.education-video {
  text-align: center;
  width: 300px;
  margin-left: 25px;
}

.resume-social .btn-xs {
    margin-left: 5px;
}

@media only screen and (max-width: 480px) {
  .images.images img {
    width: 12%;
  }
}

@media print {
  .resume-print {
    display: none;
  }

  .resume-certificate small {
    display: none;
  }

  .line {
    display: none;
  }

  .resume-certificate {
    display: none;
  }
}
